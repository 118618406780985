import React, { useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useGetImplementationGuidesQuery, useUpdateImplementationGuideVersionMutation, useDeleteImplementationGuideVersionMutation  } from '../services/adminApi';
import { CircularProgress, Box, Button, ButtonGroup } from '@mui/material';
//import DropdownCell from './DropdownCell';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';



const DataGridTable: React.FC = () => {
    const { data: items, error, isLoading } = useGetImplementationGuidesQuery();
    const [postUpdateVersion] = useUpdateImplementationGuideVersionMutation();
    const [deleteImplementationGuide] = useDeleteImplementationGuideVersionMutation();
    const [itemOptions, setItemOptions] = useState<Record<string, string>>({});

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <div>Error loading items</div>;
    }

    const handleOptionChange = (id: string, version: string) => {
        setItemOptions({
            ...itemOptions,
            [id]: version,
        });
    };

    const handleButtonClick = (id: string) => {
        const version = itemOptions[id];
        postUpdateVersion({ packageId: id, packageVersion: version }).unwrap()
            .then(() => {
                console.log(`Option ${version} selected for item ${id}`);
            })
            .catch((error) => {
                console.error('Error selecting option:', error);
            });
    };

    const handleRemoveButtonClick = (id: string, version: string) => {
        deleteImplementationGuide({ id, version }).unwrap()
            .then(() => {
                console.log(`Option ${version} selected for item ${id}`);
            })
            .catch((error) => {
                console.error('Error selecting option:', error);
            });
    };

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id', width: 200 },
        { field: 'version', headerName: 'Version', width: 150 },
        { field: 'created', headerName: 'Oprettet', width: 250 },
        //{
        //    field: 'update',
        //    headerName: 'Tilgængelige versioner',
        //    width: 200,
        //    renderCell: (params: GridRenderCellParams) => (
        //        <DropdownCell
        //            id={params.row.id}
        //            version={itemOptions[params.row.id] || params.value}
        //            onChange={(value) => handleOptionChange(params.row.id, value)}
        //        />
        //    ),
        //},
        { 
            field: 'current',
            headerName: 'Current',
            width: 250,
            renderCell: (params: GridRenderCellParams) => (
                params.row.current ? <CheckBox /> : <CheckBoxOutlineBlank />
            )  
        },
        {
            field: 'action',
            headerName: 'Handlinger',
            width: 250,
            renderCell: (params: GridRenderCellParams) => (
                <Button
                variant="contained"
                color="primary"
                onClick={() => handleRemoveButtonClick(params.row.id, params.row.version)}
            >
                Fjern
            </Button>
            
            ),
        },
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid 
                //getRowId={(row) => row.name}
                rows={items || []} 
                columns={columns} 
                initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                //checkboxSelection
                disableMultipleRowSelection />
        </div>
    );
};

export default DataGridTable;
