import { AlternateEmail, Article, Build, Category, Contacts, ContentPasteGo, Gite, Laptop, Person, Restore, Router, SettingsEthernet } from "@mui/icons-material";
//import BusinessIcon from '@mui/icons-material/Business';
//import HandshakeIcon from '@mui/icons-material/Handshake';
//import VerifiedUser from "@mui/icons-material/VerifiedUser";
import { Button, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import getEnvironment from "../../env";
//import { PermissionEnum } from "../../feature/Permission/PermissionEnum";
//import { useGetServicesByOrganisationAndApproverQuery } from "../../feature/ServiceApi/redux/serviceApiSlice";
//import { AbilityContext, Can } from "../../feature/UserApi/logic/Can";
//import { UserContext } from "../../feature/UserApi/logic/FetchUser";
//import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
//import OrganisationPicker, { OrganisationContext } from "./OrganisationPicker";

interface TopBarProps {
    drawerWidth: number
}

export const SideMenu = (props: TopBarProps) => {
    const { t } = useTranslation()
    //const organisationContext = useContext(OrganisationContext)?.organisation
    //const userContext = useContext(UserContext);
    //const ability = useContext(AbilityContext);
    
    //const isSdnReadOrAdmin = userContext?.allowEmptyOrganisation();



    return (
        <Drawer
            sx={{
                width: props.drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: props.drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >

            <Button component={Link} to="/">
                <img style={{ height: "3.6em" }} src={"/medcom_logo.png"} alt="medcom" />
            </Button>

            <Divider />
            <List>
                
                <ListItem>
                    <ListItemButton component={Link} color="inherit" to="/">
                        <ListItemIcon>
                            <Category />
                        </ListItemIcon>
                        <ListItemText primary={t("EHMI Admin") + ""} />
                    </ListItemButton>
                </ListItem>
                
            </List>
        </Drawer >
    )
}