import React, { useState, useEffect } from 'react';
import { ErrorResponse, UploadError, useGetPackagesQuery, useUpdateImplementationGuideVersionMutation } from '../services/adminApi';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, SelectChangeEvent, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { error } from 'console';
import getEnvironment from "../env";

const PackageSelector: React.FC = (): JSX.Element => {
    const [useBuildserver, setUseBuildserver] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [uploadErrors, setUploadErrors] = useState<UploadError[]>([]);
    const [selectedPackageId, setSelectedPackageId] = useState('');
    const [selectedPackageVersion, setSelectedPackageVersion] = useState('');

    const { data: packages, error, isLoading } = useGetPackagesQuery(useBuildserver);
    const [postSelectedPackage, { isLoading: isPosting, error: postError }] = useUpdateImplementationGuideVersionMutation();

    useEffect(() => {
        if (packages && packages.length > 0) {
            setSelectedPackageId(packages[0].id);
        }
    }, [packages]);

    useEffect(() => {
        if (selectedPackageId && packages) {
            const selectedPackage = packages.find(pkg => pkg.id === selectedPackageId);
            if (selectedPackage && selectedPackage.versions.length > 0) {
                setSelectedPackageVersion(selectedPackage.versions[0]);
            }
        }
    }, [selectedPackageId, packages]);

    const handleUseBuildserverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUseBuildserver(event.target.checked);
        setSelectedPackageId('');
        setSelectedPackageVersion('');
    };

    const handlePackageIdChange = (event: SelectChangeEvent) => {
        setSelectedPackageId(event.target.value as string);
        setSelectedPackageVersion('');
    };

    const handlePackageVersionChange = (event: SelectChangeEvent) => {
        setSelectedPackageVersion(event.target.value as string);
    };

    const handleClose = () => {
        setErrorDialogOpen(false);
        setUploadErrors([]);
    };

    const handleSubmit = async () => {
        if (selectedPackageId && selectedPackageVersion) {
            try {
                await postSelectedPackage({ packageId: selectedPackageId, packageVersion: selectedPackageVersion }).unwrap();
                //alert('Package selected successfully!');
            } catch (error) {
                if (isErrorResponse(error) && error.status === 422) {
                    setUploadErrors(error.data);
                    setErrorDialogOpen(true);
                    console.error('fsdfsdfsdfsdf:', error);
                } else {
                    console.error('Error uploading file:', error);
                }
            }
        }
        
    };
    const handleButtonClick = async () => {
        
        await postSelectedPackage({ packageId: selectedPackageId, packageVersion: selectedPackageVersion }).unwrap()
            .then(() => {
                console.log(`Option ${selectedPackageVersion} selected for item ${selectedPackageId}`);
            })
            .catch((error) => {
                console.error('Error selecting option:', error);
            });
            
    };

    //if (isLoading || isPosting) return <div>Loading...</div>;
    //if (error || postError) return <div>Error loading data</div>;

    const selectedPackage = packages?.find(pkg => pkg.id === selectedPackageId);

    return (
        <>
            { getEnvironment().REACT_APP_NODE_ENV === "dev" ?
            // midlertidig disabled, skal løse fremsøgning i backend..
            //<FormControlLabel
            //    control={<Checkbox checked={useBuildserver} onChange={handleUseBuildserverChange} />}
            //    label="Use Buildserver"
            ///>
            <></>
            :
            <></>
            }
            <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="package-id-label">Package ID</InputLabel>
                <Select
                    labelId="package-id-label"
                    value={selectedPackageId}
                    onChange={handlePackageIdChange}
                    label="Package ID"
                >
                    {packages?.map((pkg) => (
                        <MenuItem key={pkg.id} value={pkg.id}>
                            {pkg.id}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="package-version-label">Package Version</InputLabel>
                <Select
                    labelId="package-version-label"
                    value={selectedPackageVersion}
                    onChange={handlePackageVersionChange}
                    label="Package Version"
                    disabled={!selectedPackage}
                >
                    {selectedPackage?.versions.map((version) => (
                        <MenuItem key={version} value={version}>
                            {version}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Installer
            </Button>


        <Dialog open={errorDialogOpen} onClose={handleClose}>
        <DialogTitle>Error Uploading File</DialogTitle>
        <DialogContent>
            <List>
                {uploadErrors.map((error, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={`Filename: ${error.filename}`}
                            secondary={`Error: ${error.message}`}
                        />
                    </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
        </Dialog>
        
        </>
    );
};

// Type guard to check if the error is an ErrorResponse
function isErrorResponse(error: any): error is ErrorResponse {
    return typeof error === 'object' && error !== null && 'status' in error && 'data' in error;
}
export default PackageSelector;
