import { t } from 'i18next';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { UserContext } from '../../feature/User/logic/FetchUser';


import { RootState } from '../../redux/store';
import { Box, CssBaseline } from '@mui/material';
import { TopBar } from './TopBar';
import { SideMenu } from './SideMenu';

type LayoutProps = {
    children: JSX.Element
}


const Layout = (props: LayoutProps) => {
    const loggedInAs = useContext(UserContext);
    const drawerWidth = 240;

    return (
        <>
        
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <TopBar drawerWidth={drawerWidth}></TopBar>
                <SideMenu drawerWidth={drawerWidth}></SideMenu>
                <Box
                    component="main"
                    sx={{ marginTop: 10, flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                >
                    {props.children}
                    {/*
                    <AutoBreadcrump />
                    {props.children}
                    <MuiXLicense />
                    */}
                </Box>
            </Box>
        </>
    )
    /*
    return (
        <>
            <div>
                {t("Your user has name") + ""} : {loggedInAs?.name}
            </div>
            <main>{props.children}</main>
            <div>
                {t("this is the footer") + ""}
            </div>
        </>
    )
        */
}

export default Layout;