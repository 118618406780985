
import './App.css';
import store from './redux/store'
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './pages/home';
import Layout from './components/Layout/Layout';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { StrictMode, useEffect, useMemo, useState } from 'react';
import keycloak from './feature/Keycloak/Keycloak';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { GetJWTToken, LoginBasedOnToken, UserContext } from './feature/User/logic/FetchUser';
import { AbilityContext } from './feature/User/logic/Can';
import { User } from './models/User';
import Loading from './components/loading';

/* eslint import/no-webpack-loader-syntax: off */
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';

function App() {

    const [user, setUser] = useState<User | undefined>(undefined)
    //const keycloak = useKeycloak();
    const { keycloak, initialized } = useKeycloak();

    useEffect(() => {
        const fetchUser = async () => {
          if (initialized) { // Check initialized here
            const jwt = await GetJWTToken(keycloak);
            const user = await LoginBasedOnToken(jwt!);
    
            setUser(user);
          }
        };
    
        fetchUser();
      }, [initialized, keycloak]); // Keep the dependency
    
      if (!initialized) {
        return <>Keycloak is not initialized</>;
      }
    
      if (user === undefined) {
        return <Loading />;
      }

    console.log("Keycloak initialized:", initialized);
    console.log("User:", user);

    /*
    if (!keycloak.initialized) {
        return <>Keycloak is not initialised</>
    }

    if (user == undefined) {
        return <Loading />;
    }
        */

    return (
        <Router>
            <UserContext.Provider value={user}>
                <AbilityContext.Provider value={user.getAbility()}>
                    <Layout>
                        <>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                            </Routes>

                            <ToastContainer closeButton={true} position="bottom-right" />
                        </>
                    </Layout>
                </AbilityContext.Provider>
            </UserContext.Provider>
        </Router >
    )
}

const AppWrapper = () => {
    return (
        <Provider store={store}>
            <ReactKeycloakProvider
                initOptions={{
                    onLoad: 'login-required',
                    checkLoginIframe: false
                }}
                authClient={keycloak}
                autoRefreshToken={true}
            >
                <StrictMode>
                    <App />
                </StrictMode>
            </ReactKeycloakProvider>
        </Provider >
    )
}


export default AppWrapper;
