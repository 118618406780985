import getEnvironment from "../env";
import { Stack, Box, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material"
import { ValidationSchemaExample } from "../feature/TodoApi/form"
import { TodoList } from "../feature/TodoApi/todoList"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadForm from "../components/UploadForm";
import UploadUrl from "../components/UploadUrl";
import DataGridTable from "../components/DataGridTable";
import PackageSelector from "../components/PackageSelector";

export const HomePage = () => {
    console.log("Rendering HomePage");

    console.log("XXXX " + getEnvironment().REACT_APP_NODE_ENV)
    return (
        <>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                divider={
                    <Box
                        component="hr"
                        sx={{
                            border: (theme) =>
                            `1px solid ${theme.palette.mode === 'dark' ? '#262B32' : '#fff'}`,
                        }}
                    />
                }
                spacing={4}
            >
          
            <PackageSelector></PackageSelector>
            
            { getEnvironment().REACT_APP_NODE_ENV === "dev" ?
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Andre installationsmuligheder</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <UploadForm />
                    <UploadUrl />
                </AccordionDetails>
            </Accordion>
            :
            <></>
            }
            {/* <InputForm />
            <UploadForm /> */}
            


            <DataGridTable />
        </Stack>
        </>
    )
}